$turquoise: #21b899;
$light-turquoise:#36c0a6;
$orange:#e76227;
$dark-orange: #b54726;
$brown: #3f1f10;
$crispy: #6DA68B;
$bile: #F2C46D;
$sandstone: #F2DEC4;
$selita: #D96B43;
$gutentag: #D9564A;
$gray: #201e1d;
$lightergray: #88837c;


