/*
    Colorbox Core Style:
    The following CSS is consistent between example themes and should not be altered.
*/
#colorbox, #cboxOverlay, #cboxWrapper{
position:absolute;
top:0;
left:0;
z-index:9999;
overflow:auto;
}
#cboxOverlay{
position:fixed;
width:100%;
height:100%;
}
#cboxMiddleLeft, #cboxBottomLeft{
clear:left;
}
#cboxContent{
position:relative;
}
#cboxLoadedContent{
overflow:auto;
-webkit-overflow-scrolling:touch;
}
#cboxTitle{
margin:0;
}
#cboxLoadingOverlay, #cboxLoadingGraphic{
position:absolute;
top:0;
left:0;
width:100%;
height:100%;
}
#cboxPrevious, #cboxNext, #cboxClose, #cboxSlideshow{
cursor:pointer;
}
.cboxPhoto{
float:left;
margin:auto;
border:0;
display:block;
max-width:none;
-ms-interpolation-mode:bicubic;
}
.cboxIframe{
width:100%;
height:100%;
display:block;
border:0;
}
#colorbox, #cboxContent, #cboxLoadedContent{
box-sizing:content-box;
-moz-box-sizing:content-box;
-webkit-box-sizing:content-box;
}
/* 
    User Style:
    Change the following styles to modify the appearance of Colorbox.  They are
    ordered & tabbed in a way that represents the nesting of the generated HTML.
*/
#cboxOverlay{
background:#000;
}
#colorbox{
outline:0;
}
#cboxContent{
margin-top:20px;
background:#fff;
}
.cboxIframe{
background:#fff;
}
#cboxError{
padding:50px;
border:1px solid #ccc;
}
#cboxLoadedContent{
border:15px solid #fff;
background:#fff;
}
#cboxTitle{
position:absolute;
bottom:15px;
left:0;
width:100%;
text-align:center;
padding:0 10px;
font-size:14px;
line-height:38px;
color:#ddd;
background:rgba(0,0,0,0.6);
border-left:15px solid #fff;
border-right:15px solid #fff;
}
#cboxCurrent{
position:absolute;
top:15px;
left:50%;
margin:0 0 0 -60px;
padding:5px 10px;
font-size:14px;
font-weight:600;
color:#fff;
text-shadow:1px 1px 1px #555;
background:transparent;
}
#cboxLoadingGraphic{
//background:url("images/status.gif") no-repeat center center;
}
/* these elements are buttons, and may need to have additional styles reset to avoid unwanted base styles */
        #cboxPrevious, #cboxNext, #cboxSlideshow, #cboxClose{
border:0;
padding:0;
margin:0;
overflow:visible;
width:auto;
background:none;
}
/* avoid outlines on :active (mouseclick), but preserve outlines on :focus (tabbed navigating) */
        #cboxPrevious:active, #cboxNext:active, #cboxSlideshow:active, #cboxClose:active{
outline:0;
}
#cboxSlideshow{
position:absolute;
top:-20px;
right:90px;
color:#fff;
}
#cboxPrevious,#cboxNext{
margin:-27px 0 0;
display:block;
width:40px;
height:54px;
position:absolute;
top:50%;
z-index:10;
cursor:pointer;
text-indent:-9999px;
opacity:1;
/*transition*/
-webkit-transition:background-color 0.2s ease-in;
   -moz-transition:background-color 0.2s ease-in;
     -o-transition:background-color 0.2s ease-in;
        transition:background-color 0.2s ease-in;
}

#cboxNext:hover,#cboxPrevious:hover{
background-color:#5AAFE0;
}
#cboxClose{
position:absolute;
top:15px;
right:15px;
display:block;
padding:20px;
background-color:rgba(0, 0, 0, 0.6);
/*border-radius*/
-webkit-border-radius:0 0 0 3px;
   -moz-border-radius:0 0 0 3px;
        border-radius:0 0 0 3px;
width:40px;
height:40px;
text-indent:-9999px;
}
#cboxClose:hover{
background-color:#5AAFE0;
opacity:1;
}
