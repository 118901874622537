
.vert-center {
  display: table;
  width: 100%
}

.vert-center .vert-content {
  display: table-cell;
  vertical-align: middle
}

.container-fluid {
  padding-left: 25px;
  padding-right: 25px
}

.row {
  margin-left: 0;
  margin-right: 0
}

.off-white {
  background-color: #eeeaee
}

.green {
  background-color: $turquoise
}

.green h1 {
  color: #fff
}

#above_nav {
  display: block;
  //position:relative;
  text-align: center;
  height: 100%;
  bottom: 86px
}

#above_nav:after {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  height: 7px;
  width: 100%;
  background: url('/assets/separator.png') repeat-x
}

.form_response#above_nav .region-above-nav {
  background-color: rgba(0, 0, 0, 0.5);
}

#above_nav div {
  height: 100%
}

#above_nav h1 {
  font-size: 28px;
  font-weight: 100;
  letter-spacing: 10px;
  color: #fff;
  text-transform: uppercase;
  margin: 0
}

#above_nav h2 {
  font-size: 30px;
  font-weight: 600;
  color: #fff;
  line-height: 1.3;
  text-transform: uppercase;
  max-width: 810px;
  margin: 20px auto
}

#above_nav h3 {
  font-size: 20px;
  font-weight: 100;
  letter-spacing: 10px;
  color: #fff;
  text-transform: uppercase;
  margin: 0
}

.form_response#above_nav h2 {
  text-transform: initial;
}
.form_response#above_nav p{
  font-size: 20px;
  font-weight: 100;
  color: #fff;
  margin: 20px auto
}

#above_nav .contextual-links-wrapper {
  position: absolute;
  height: auto;
  top: 20px;
  right: 20px
}

#above_nav .scroll-down {
  height: auto;
  position: relative
}

#above_nav .gosky-down-open {
  font-size: 20px;
  color: #fff;
  display: block;
  position: absolute;
  left: 50%;
  margin-left: -14px;
  margin-top: 80px
}

@include media-breakpoint-down(md) {
  #above_nav img {
    max-width: 100%;
    height: auto;
  }
}

/* default screen, non-retina */
#above_nav {
  background-size: 100%;
  background-size: cover;
  background-position: bottom center;
  background-repeat: no-repeat;
  background-image: url("/assets/outskirts-bg-1600-s2.png");

  @media only screen and (max-width: 800px) {
    /* Small screen, non-retina */
    background-image: url('/assets/outskirts-bg-800-s2.png');
  }
  @media only screen and (min-resolution: 2dppx) and (max-width: 800px) {
    /* Small screen, retina */
    background-image: url('/assets/outskirts-bg-1600-s2.png');
  }
  @media only screen and (min-resolution: 2dppx) and (min-width: 800px) {
    background-image: url('/assets/outskirts-bg-3200-s2.png');
  }
}

.front #above_nav .container {
}

@media only screen and (max-width: 768px) {
  .front #above_nav .container {
    background-size: 100%
  }
}


