/*--------------------
-----Main Styles------
----------------------*/
body {
  //font-size: 18px;
  color: #7a7c7f;
  color: #524b47;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  letter-spacing: 0.02em;
}

p {
  //font-size: 18px;
  //line-height: 24px;
  //font-weight: 300;
  //margin-bottom: 20px;
}

.lead {
  line-height: 1.25;
  letter-spacing: 0.02em;
  margin-bottom: 40px;
}

a,
a:hover,
a:focus {
  outline: none !important;
  text-decoration: none;
}

a {
  color: #5cc9df;
  text-decoration: none;
}

a:hover,
a:focus {
  color: #5cc9df;
  text-decoration: underline;
}

a:focus,
.btn:focus {
  outline: none;
}

.color-bg a {
  color: #fff;
  text-decoration: underline;
}

.color-bg a:hover,
.color-bg a:focus {
  color: #fff;
  text-decoration: none;
}

.primary {
  color: #5cc9df;
}

/*----------------------
---Main Layout Styles---
------------------------*/
.img-center {
  margin: 0 auto;
}

.padding-top-bottom {
  padding: 100px 0;
}

.padding-top {
  padding: 100px 0 0;
}
//
//.page-title {
//  margin: 0;
//  padding-top: 80px;
//  //font-family:'Dosis',Arial,sans-serif;
//  //font-size: 64px;
//  //line-height: 78px;
//  font-weight: 400;
//  letter-spacing: 0.03em;
//  text-align: center;
//}
//
//.page-subtitle {
//  margin: 0;
//  margin-top: 10px;
//  font-size: 24px;
//  line-height: 21px;
//  font-weight: 300;
//  font-style: italic;
//  letter-spacing: 0.02em;
//  text-align: center;
//}
//
//.page-subtitle:before {
//  position: relative;
//  top: 14px;
//  margin-right: 5px;
//  content: '\201C';
//  font-size: 52px;
//}
//
//.page-subtitle:after {
//  position: relative;
//  top: 32px;
//  margin-left: 5px;
//  content: '\201D';
//  font-size: 52px;
//}

.section-title {
  position: relative;
  margin-bottom: 40px;
  //font-size: 42px;
  line-height: 64px;
  font-weight: 700;
  letter-spacing: 0.03em;
  text-align: center;
  text-transform: uppercase;
}

.section-title:after {
  display: block;
  content: "";
  width: 100px;
  height: 4px;
  position: absolute;
  left: 50%;
  bottom: -15px;
  margin-left: -50px;
  background: #5cc9df;
  border-radius: 3px;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.08);
}

.section-description {
  text-align: center;
  font-weight: 400;
  margin-bottom: 60px;
}

.subsection-title {
  text-align: center;
  margin-top: 80px;
}

.subsection-description {
  text-align: center;
  margin-bottom: 60px;
}

/*------------------
----- Buttons ------
--------------------*/

.btn-qubico {
  border: none;
  font-family: inherit;
  font-size: inherit;
  cursor: pointer;
  padding: 15px 30px;
  display: inline-block;
  margin: 20px 0;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 1px;
  font-weight: 600;
  outline: none;
  position: relative;
  background: #5cc9df;
  color: #fff;
  box-shadow: 0 4px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  -webkit-transition: none;
  -moz-transition: none;
  transition: none;
}

.btn-qubico:hover,
.btn-qubico:focus {
  color: #fff;
  box-shadow: 0 3px rgba(0, 0, 0, 0.07);
  top: 1px;
  text-decoration: none;
}

.btn-qubico:active {
  color: #fff;
  box-shadow: 0 2px rgba(0, 0, 0, 0.07);
  top: 2px;
  text-decoration: none;
}

.btn-qubico.white {
  background: transparent;
  border: 2px solid #fff;
  box-shadow: none;
  text-decoration: none;
  -webkit-transition: all .3s ease-out;
  transition: all .3s ease-out;
}

.btn-qubico.white:hover,
.btn-qubico.white:focus,
.btn-qubico.white:active {
  background: #fff;
  color: #5CC9DF;
  top: 0;
  box-shadow: 0 3px rgba(0, 0, 0, 0.1);
  text-decoration: none;
}

.btn-qubico [class*=fa] {
  margin-right: 10px;
}

/*----------------
----- Colors -----
------------------*/

.white-bg {
  background: #fff;
}

.gray-bg {
  background: #f3f3f3;
}

.color-bg {
  background: #5cc9df;
}

.dark-bg {
  background: #4E5961;
}

.light-typo {
  color: #fff;
}

.color-bg.light-typo .section-title:after {
  background: #fff;
}

/*----------------------
----Navigation Styles---
------------------------*/

.site-logo {
  max-height: 54px;
}

.logo-small {
  display: inline-block;
  vertical-align: top;
  margin-right: 5px;
  opacity: 1;
  -webkit-transition: all .2s ease-in;
  transition: all .2s ease-in;
}

.navbar-brand.scrollto:hover .logo-small {
  opacity: 0;
  -webkit-transform: translateY(-50px);
  transform: translateY(-50px);
  -webkit-transition: all .3s ease-out;
  transition: all .3s ease-out;
}

/*-----------------------
-----Services Styles-----
-------------------------*/
.services {
  margin-top: 80px;
  margin-bottom: 40px;
}

.services .icon {
  position: relative;
  width: 150px;
  height: 150px;
  margin: 0 auto;
  text-align: center;
  background: #5CC9DF;
  line-height: 150px;
  font-size: 48px;
  color: #fff;
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.0);
  margin-bottom: 40px;
  -webkit-transition: all .3s ease-out;
  transition: all .3s ease-out;
}

.services .item:hover .icon {
  font-size: 38px;
  box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.05);
}

.services .icon:after {
  display: block;
  content: "";
  position: absolute;
  width: 150px;
  height: 150px;
  top: 0;
  left: 0;
  border-radius: 50%;
  box-shadow: inset 0 0 0 0 rgba(0, 0, 0, 0.08);
  background: transparent;
  -webkit-transition: box-shadow .3s ease-out;
  transition: box-shadow .3s ease-out;
}

.services .item:hover .icon:after {
  box-shadow: inset 0 0 0 10px rgba(0, 0, 0, 0.08);
}

.services h3 {
  font-size: 1rem;
  -webkit-transition: color .3s ease-out;
  transition: color .3s ease-out;
}

.services .item:hover h3 {
  color: #5CC9DF;
}

.services p {
  padding: 0 20px;
  font-size: 0.8rem;
  //line-height: 21px;
}

/*----------------------
-----Contact Styles-----
------------------------*/
.contact-item h2 {
  font-size: 18px;
  margin-bottom: 10px;
}

.contact-item .icon {
  display: block;
  font-size: 48px;
  color: #5cc9df;
  text-shadow: -2px 2px 0 rgba(0, 0, 0, 0.1);
  -webkit-transition: all .3s ease-out;
  transition: all .3s ease-out;
}

.contact-item .icon:hover {
  color: #5cc9df;
  -webkit-transform: scale(1.3) translateY(-10px);
  transform: scale(1.3) translateY(-10px);
}

.bl_form {
  margin: 30px 0 0;
}

.bl_form input {
  padding-top: 15px;
  background: rgba(255, 255, 255, 0.10);
  box-shadow: 0 4px 0px rgba(0, 0, 0, 0.2);
  border: none;
  color: white;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 16px;
  outline: none;
}

.lb_wrap .lb_label.top, .lb_wrap .lb_label.bottom {
  left: 66px !important;
}

.lb_wrap .lb_label.left {
  left: 0;
}

.lb_label {
  font-size: 18px;
  line-height: 32px;
  font-weight: 400;
  color: #ccc;
}

.no-placeholder .lb_label {
  display: none;
}

.lb_label.active {
  color: #aaa;
}

#contact-form .form-group label {
  display: none;
  font-size: 18px;
  line-height: 24px;
  font-weight: 100;
  text-transform: uppercase;
}

#contact-form.no-placeholder .form-group label {
  display: block;
}

#contact-form .controls {
  padding: 0;
  margin-top: 40px;
}

#contact-form.no-placeholder .controls {
  margin-top: 0;
}

#contact-form .form-control {
  display: inline;
  background: #fff;
  border: none;
  border-radius: 5px;
  outline: none;
  box-shadow: 0 4px 0 rgba(0, 0, 0, 0.05);
  height: 52px;
  font-size: 18px;
  line-height: 32px;
  color: #aaa;
  padding-left: 64px;
  vertical-align: top;
}

#contact-form .form-group.half-width {
  width: 40%;
  float: left;
}

#contact-form .form-group {
  position: relative;
}

#contact-form .form-group [class*=fa] {
  display: block;
  width: 64px;
  position: absolute;
  top: 0;
  left: 5px;
  color: #eee;
  font-size: 24px;
  line-height: 52px;
  text-align: center;
  -webkit-transition: color .3s ease-out;
  transition: color .3s ease-out;
}

#contact-form .form-group [class*=fa].active {
  color: #ccc;
}

#contact-form.no-placeholder .form-group [class*=fa] {
  top: 30px;
}

#contact-form textarea.form-control {
  height: auto;
  max-width: 100%;
  min-width: 100%;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  padding-top: 14px;
  vertical-align: top;
}

#contact-form .form-control:focus {
  outline: none;
  box-shadow: 0 4px 0 rgba(0, 0, 0, 0.05);
}

#contact-form .error-message {
  padding: 5px 0;
  position: absolute;
  top: -35px;
  right: 0;
  font-size: 15px;
  line-height: 24px;
  font-weight: 400;
  color: #ff3345;
  z-index: 10;
}

#contact-form.no-placeholder .error-message {
  top: 0;
}

/*----------------------
-----Footer Styles------
------------------------*/

#imprint {
  padding: 40px 0 20px;
  box-shadow: 0 -4px 0 rgba(0, 0, 0, 0.1);
}

.social-links {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
}

.social-links li {
  display: inline-block;
  margin: 0 10px 5px;
  border-radius: 3px;
  box-shadow: 0 4px 0 transparent;
  -webkit-transition: all .3s ease-out;
  transition: all .3s ease-out;
}

.social-links li a {
  display: block;
  color: #fff;
  font-size: 30px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  -webkit-transition: all .3s ease-out;
  transition: all .3s ease-out;
}

.social-links li:hover {
  background: #fff;
  box-shadow: 0 4px 0 rgba(0, 0, 0, 0.05);
}

.social-links li:hover a {
  color: #5CC9DF;
}

/*-----------------------
-----Scrollimations------
------------------------*/
.scrollimation.fade-in {
  opacity: 0;
  -webkit-transition: opacity 0.6s ease-out;
  transition: opacity 0.6s ease-out;
}

.scrollimation.fade-in.in {
  opacity: 1;
}

.scrollimation.scale-in {
  opacity: 0;
  -webkit-transform: scale(0.5);
  -moz-transform: scale(0.5);
  -ms-transform: scale(0.5);
  transform: scale(0.5);
  -webkit-transition: -webkit-transform .6s cubic-bezier(.17, .89, .67, 1.57), opacity .6s ease-out;
  transition: transform .6s cubic-bezier(.17, .89, .67, 1.57), opacity .6s ease-out;
}

.scrollimation.scale-in.in {
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.scrollimation.fade-right {
  opacity: 0;
  -webkit-transform: translateX(-100px);
  -moz-transform: translateX(-100px);
  -ms-transform: translateX(-100px);
  transform: translateX(-100px);
  -webkit-transition: -webkit-transform .4s ease-out, opacity .4s ease-out;
  transition: transform .4s ease-out, opacity .4s ease-out;
}

.scrollimation.fade-right.in {
  opacity: 1;
  -webkit-transform: translateX(0px);
  -moz-transform: translateX(0px);
  -ms-transform: translateX(0px);
  transform: translateX(0px);
}

.scrollimation.fade-left {
  opacity: 0;
  -webkit-transform: translateX(100px);
  -moz-transform: translateX(100px);
  -ms-transform: translateX(100px);
  transform: translateX(100px);
  -webkit-transition: -webkit-transform .4s ease-out, opacity .4s ease-out;
  transition: transform .4s ease-out, opacity .4s ease-out;
}

.scrollimation.fade-left.in {
  opacity: 1;
  -webkit-transform: translateX(0px);
  -moz-transform: translateX(0px);
  -ms-transform: translateX(0px);
  transform: translateX(0px);
}

.scrollimation.fade-up {
  opacity: 0;
  -webkit-transform: translateY(100px);
  -moz-transform: translateY(100px);
  -ms-transform: translateY(100px);
  transform: translateY(100px);
  -webkit-transition: -webkit-transform .4s ease-out, opacity .4s ease-in;
  transition: transform .4s ease-out, opacity .4s ease-in-out;
}

.scrollimation.fade-up.in {
  opacity: 1;
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
}

.scrollimation.d1 {
  transition-delay: .2s;
}

.scrollimation.d2 {
  transition-delay: .4s;
}

.scrollimation.d3 {
  transition-delay: .6s;
}

.scrollimation.d4 {
  transition-delay: .8s;
}

.touchevents .scrollimation.fade-in {
  opacity: 1;
}

.touchevents .scrollimation.scale-in {
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.touchevents .scrollimation.fade-right {
  opacity: 1;
  -webkit-transform: translateX(0px);
  -moz-transform: translateX(0px);
  -ms-transform: translateX(0px);
  transform: translateX(0px);
}

.touchevents .scrollimation.fade-left {
  opacity: 1;
  -webkit-transform: translateX(0px);
  -moz-transform: translateX(0px);
  -ms-transform: translateX(0px);
  transform: translateX(0px);
}

.touchevents .scrollimation.fade-up {
  opacity: 1;
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
}

/*----------------------
-----Media Queries------
------------------------*/
@media (max-width: 991px) {

}

@media (min-width: 768px) {


}

@media (max-width: 767px) {

  body, p {
    //font-size: 16px;
    //line-height: 21px;
  }

  h1,
  .skills h2 {
    //font-size: 24px;
    //line-height: 28px;
  }

  h2 {
    //font-size: 21px;
    //line-height: 24px;
  }

  .page-title {
    //font-size: 42px;
    //line-height: 52px;
  }

  .page-subtitle {
    //font-size: 18px;
    //line-height: 21px;
  }

  .page-subtitle:before {
    //top: 7px;
    //font-size: 32px;
  }

  .page-subtitle:after {
    //top: 21px;
    //font-size: 32px;
  }
  .section-title {
    //font-size: 28px;
    //line-height: 36px;
  }

  .section-description {
    //font-size: 18px;
  }

  .cta .cta-message p,
  .cta .cta-message strong {
    //font-size: 18px;
    //line-height: 24px;
  }

  .cta .cta-message {
    text-align: center;
  }

  .services .item {
    margin-bottom: 60px;
  }
}

@media (max-width: 479px) {

}
